import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './ButtonsStyles';

export const BaseButton = ({
  text,
  type,
  size,
  onClick,
  disabled,
  loading,
  shape,
  htmlType,
  align,
  icon,
  style,
  block
}) => (
  <StyledButton
    style={style}
    type={type}
    block={block}
    size={size}
    onClick={onClick}
    disabled={disabled}
    loading={loading}
    shape={shape}
    htmlType={htmlType}
    align={align}
    icon={icon}
  >
    {text}
  </StyledButton>
);

BaseButton.propTypes = {
  type: PropTypes.oneOf([
    'default',
    'primary',
    'ghost',
    'dashed',
    'danger',
    'dashed danger',
    'link'
  ]).isRequired,
  htmlType: PropTypes.oneOf(['reset', 'submit', 'button']).isRequired,
  size: PropTypes.oneOf(['small', 'default', 'large']).isRequired,
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  shape: PropTypes.oneOf(['circle', 'round', undefined]),
  align: PropTypes.oneOf(['left', 'right', 'initial']),
  icon: PropTypes.string,
  ghost: PropTypes.bool,
  block: PropTypes.bool
};

BaseButton.defaultProps = {
  type: 'default',
  htmlType: 'button',
  size: 'default',
  ghost: false,
  block: false
};

export default BaseButton;
