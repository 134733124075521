import styled from 'styled-components';
import { Button } from 'antd';

export const StyledButton = styled(Button)`
  float: ${p => (p.align ? p.align : 'left')};

  &.ant-btn-danger {
    background-color: ${p => p.theme.primaryBackgroundColor};
    color: ${p => p.theme.inputErrorColor};

    &:hover {
      background-color: ${p => p.theme.inputErrorColor};
      color: white;
    }
  }
`;

export const FloatButton = styled(Button)`
  position: absolute;
  top: 15px;
  right: ${({ position }) =>
    position
      ? position === 1
        ? '70px'
        : `${(position - 1) * 55 + 70}px`
      : '15px'};

  &.ant-btn-danger {
    background-color: ${p => p.theme.primaryBackgroundColor};
    color: ${p => p.theme.inputErrorColor};

    &:hover {
      background-color: ${p => p.theme.inputErrorColor};
      color: white;
    }
  }
`;
