export const ImageFormData = image => {
  const payload = new FormData();
  payload.append('file', image.blob);
  return payload;
};

const ConstructFormData = data => {
  const payload = new FormData();
  const keys = Object.keys(data);

  keys.forEach(key => {
    if (data[key] && typeof data[key] == 'string')
      payload.append(key, data[key]);
    else if (data[key] && Array.isArray(data[key])) {
      data[key].forEach(value => {
        value.blob
          ? payload.append('file', value.blob)
          : payload.append(key, value);
      });
    } else if (data[key] && typeof data[key] == 'object') {
      Object.keys(data[key]).forEach(subKey => {
        payload.append(`${key}.${subKey}`, data[key][subKey]);
      });
    }
  });

  return payload;
};

export default ConstructFormData;
