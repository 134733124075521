import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { LoadScript } from '@react-google-maps/api';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './redux/ConfigStore';
import startThunk from './redux/thunks/StartThunk';
import { LocalizeProvider } from 'react-localize-redux';
import client from './infra/config/AxiosConfig';
import Theme from './styles/Theme';
import { ThemeProvider } from 'styled-components';

const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const store = configureStore();
client.setupInterceptors(store);
store.dispatch(startThunk());

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <LocalizeProvider store={store}>
        <ThemeProvider theme={Theme}>
          <LoadScript
            id="script-loader"
            libraries={['places']}
            googleMapsApiKey={REACT_APP_GOOGLE_API_KEY}
          >
            <App />
          </LoadScript>
        </ThemeProvider>
      </LocalizeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
