import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ImageContainer = styled.div`
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const Image = styled.div`
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const ImageComponent = ({ width, ratio, children, color, url }) => {
  return (
    <ImageContainer>
      <Image
        style={{
          width: width ? width : '100%',
          paddingTop: ratio ? 100 * ratio + '%' : '100%',
          backgroundColor: color ? color : '#FFF',
          backgroundImage: `url(${url})`
        }}
      />
      {children}
    </ImageContainer>
  );
};

ImageComponent.propTypes = {
  url: PropTypes.string,
  ratio: PropTypes.number,
  width: PropTypes.string,
  color: PropTypes.string
};
export default ImageComponent;
